import HeaderContainer_BevelScreenComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/BevelScreen/BevelScreen.skin';
import HeaderContainer_BevelScreenController from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/HeaderContainer.controller';


const HeaderContainer_BevelScreen = {
  component: HeaderContainer_BevelScreenComponent,
  controller: HeaderContainer_BevelScreenController
};


export const components = {
  ['HeaderContainer_BevelScreen']: HeaderContainer_BevelScreen
};

